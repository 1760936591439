import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const StyledIconButton = ({ href, icon }) => {
    return (
        <motion.a
            href={href}
            target="_blank"
            rel="noreferrer"
            className="inline-block relative overflow-hidden rounded-full p-[3px]"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
        >
            <svg className="absolute inset-0 w-full h-full" viewBox="0 0 50 50">
                <motion.circle
                    cx="25"
                    cy="25"
                    r="23"
                    fill="none"
                    stroke="url(#iconGradient)"
                    strokeWidth="2"
                    strokeDasharray="0 1"
                    animate={{
                        strokeDasharray: ["1 0", "0 1"]
                    }}
                    transition={{
                        duration: 5,
                        repeat: Infinity,
                        ease: "linear"
                    }}
                />
            </svg>
            <motion.div
                className="absolute inset-0 opacity-50"
                style={{
                    backgroundImage: 'linear-gradient(45deg, transparent 25%, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.2) 50%, transparent 50%, transparent 75%, rgba(255,255,255,0.2) 75%, rgba(255,255,255,0.2) 100%)',
                    backgroundSize: '10px 10px',
                }}
                animate={{
                    x: [0, 10],
                    y: [0, 10],
                }}
                transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: "linear"
                }}
            />
            <motion.div
                className="relative flex items-center justify-center w-12 h-12 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm text-white rounded-full"
                animate={{
                    boxShadow: [
                        '0 0 0 0 rgba(255,255,255,0.7)',
                        '0 0 0 10px rgba(255,255,255,0)',
                    ]
                }}
                transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "easeInOut"
                }}
            >
                <FontAwesomeIcon icon={icon} className="text-2xl" />
            </motion.div>
        </motion.a>
    );
};

const IconButtons = () => {
    return (
        <div className="flex gap-4">
            <StyledIconButton href="https://github.com/jjgithub-24" icon={faGithub} />
            <StyledIconButton href="https://www.linkedin.com/in/jalenkierre/" icon={faLinkedin} />
            <defs>
                <linearGradient id="iconGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#3b82f6">
                        <animate attributeName="stop-color" values="#3b82f6; #8b5cf6; #ec4899; #3b82f6" dur="10s" repeatCount="indefinite" />
                    </stop>
                    <stop offset="100%" stopColor="#ec4899">
                        <animate attributeName="stop-color" values="#ec4899; #3b82f6; #8b5cf6; #ec4899" dur="10s" repeatCount="indefinite" />
                    </stop>
                </linearGradient>
            </defs>
        </div>
    );
};

export default IconButtons;