import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Hero from './components/resumebutton';
import TrustedBy from "./components/trustby";

// Smooth Scroll function
const smoothScroll = (e, target) => {
    e.preventDefault();
    const element = document.querySelector(target);
    if (element) {
        window.scrollTo({
            top: element.offsetTop - 64, // Adjust for navbar height
            behavior: 'smooth'
        });
    }
};

// Navbar Component
const Navbar = () => (
    <nav className="bg-black shadow-[0_4px_10px_rgba(255,255,255,0.1)] fixed w-full z-10">
        <div className="max-w-7xl mx-auto px-4">
            <div className="flex justify-between h-16">
                <div className="flex">
                    <div className="flex-shrink-0 flex items-center">
                        <span className="text-xl font-bold text-white">Jalen Johnson</span>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="hidden md:ml-6 md:flex md:space-x-8">
                        {['About', 'Education', 'Experience', 'Projects'].map((item) => (
                            <a
                                key={item}
                                href={`#${item.toLowerCase()}`}
                                onClick={(e) => smoothScroll(e, `#${item.toLowerCase()}`)}
                                className="text-gray-300 hover:text-white px-3 py-2 text-sm font-medium"
                            >
                                {item}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </nav>
);

// Section Component
const Section = ({ id, title, children, gradient }) => (
    <motion.section
        id={id}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`${gradient} py-20`}
    >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold mb-8 text-white">{title}</h2>
            {children}
        </div>
    </motion.section>
);

// About Component
const About = () => (
    <Section id="about" title="About Me" gradient="bg-gradient-to-b from-gray-800 to-gray-700">
        <p className="text-lg text-gray-300">
            Georgia-born and raised scholar developing skills in Embedded Systems and Electrical Engineering.
            Currently building skills in coding (Python, C++, Java, MATLAB), hardware description languages (Verilog), Printed Circuit Board Design, Electronic Design Automation and Computer-Aided Design Software.
        </p>
    </Section>
);

// Education Component
const Education = () => (
    <Section id="education" title="Education" gradient="bg-gradient-to-b from-gray-700 to-gray-800">
        <div className="space-y-4">
            <div>
                <h3 className="text-xl font-semibold text-white">Bachelor of Science in Physics, con. in Applied Physics</h3>
                <p className="text-gray-300">Georgia State University, 2028</p>
            </div>
            <p className="text-gray-300">- Attending with a Stamps and Presidential Scholarship</p>
            <p className="text-gray-300">- Clubs: Robotics Club, Rocket Technologies @ GSU, Society of Physics Students @ GSU</p>
            <p className="text-gray-300">- Volunteering: Honors College Connection Project, Honors Service Initiative</p>
            <p className="text-gray-300">- Relevant Coursework: Calculus 1 </p>
            <p className="text-gray-300">- Electrical & Computer Engineering Courses Taken at Georgia Institute of Technology</p>
        </div>
    </Section>
);

// Experience Component
const Experience = () => (
    <Section id="experience" title="Work Experience" gradient="bg-gradient-to-b from-gray-800 to-gray-700">
        <div className="space-y-6">
            <div>
                <h3 className="text-xl font-semibold text-white">Undergraduate Researcher under Dr. Xiaochun He</h3>
                <p className="text-gray-300">Georgia State University, Nuclear Physics Group</p>
                <p className="text-gray-300">September 2024 - Present</p>
                <ul className="list-disc list-inside mt-2 text-gray-300">
                    <li>Developing and integrating innovative embedded systems solutions to enhance laboratory efficiency and data collection
                        capabilities in muon and photonics research, collaborating with graduate students and faculty.
                    </li>
                    <li>Designing, constructing, and optimizing muon detector technology, significantly improving data collection effectiveness,
                        and contributing to the further understanding of muons in relation to the Earth’s environment.
                    </li>
                    <li>Learning and utilizing advanced programming skills in data analysis and software development to analyze complex data
                        sets and provide technical support for laboratory operations and experiments.
                    </li>
                </ul>
            </div>
            <div>
                <h3 className="text-xl font-semibold text-white">University Assistant under Dr. Xiaojun Cao</h3>
                <p className="text-gray-300">Georgia State University, Department of Computer Science</p>
                <p className="text-gray-300">September 2024 - Present</p>
                <ul className="list-disc list-inside mt-2 text-gray-300">
                    <li>Collaborating with Dr. Cao on data-driven curriculum optimization, collecting, analyzing, and visualizing data to inform
                        graduate-level course offerings, enhancing departmental efficiency and effectiveness.
                    </li>
                    <li>Developing comprehensive programming skills through directed study, building skills in Microsoft Office, C/C++, Java,
                        Python, and fundamental computer science principles, expanding proficiency in software development and data analysis.
                    </li>
                    <li>Conducting research in quantum communication under faculty guidance, exploring cutting-edge concepts and techniques
                        to advance knowledge and application of the technology in modern systems.
                    </li>
                </ul>
            </div>
            <div>
                <h3 className="text-xl font-semibold text-white">Lead Tutor</h3>
                <p className="text-gray-300">Conti's Incredible Connections</p>
                <p className="text-gray-300">October 2018 - Present</p>
                <ul className="list-disc list-inside mt-2 text-gray-300">
                    <li>Tutoring a diverse range of 40-50 students (K5 - College Freshman), including special education
                        students, in various subjects, and coaching excelling students in mathematics to advance their
                        knowledge beyond their grade level.
                    </li>
                    <li>Assisting in hiring, mentoring, and scheduling new tutors, while implementing innovative study
                        programs to enhance students' learning and memorization techniques.
                    </li>
                    <li>Improving workplace efficiency and enjoyment by collaborating on visual, furniture, and color
                        enhancements, and maintaining a clean and organized environment.
                    </li>
                </ul>
            </div>
        </div>
    </Section>
);

// Image Carousel Component
const ImageCarousel = ({images}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div className="relative w-full max-w-xl mx-auto mb-6">
            <div className="overflow-hidden rounded-lg shadow-lg">
                <img
                    src={images[currentIndex]}
                    alt={`Project slide ${currentIndex + 1}`}
                    className="w-full h-64 object-cover transition-opacity duration-500"
                />
            </div>
            <button
                onClick={prevSlide}
                className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition-all"
            >
                <ChevronLeft className="w-6 h-6 text-black" />
            </button>
            <button
                onClick={nextSlide}
                className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition-all"
            >
                <ChevronRight className="w-6 h-6 text-black" />
            </button>
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`w-2 h-2 rounded-full ${
                            index === currentIndex ? 'bg-white' : 'bg-gray-500'
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};

// Project Card Component
const ProjectCard = ({ title, description, technologies, onClick }) => (
    <motion.div
        whileHover={{ scale: 1.05 }}
        className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-6 cursor-pointer"
        onClick={onClick}
    >
        <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
        <p className="text-gray-300 mb-4">{description}</p>
        <div className="flex flex-wrap gap-2">
            {technologies.map((tech, index) => (
                <span key={index} className="bg-indigo-900 bg-opacity-50 text-indigo-200 text-xs font-semibold px-2.5 py-0.5 rounded">
                    {tech}
                </span>
            ))}
        </div>
    </motion.div>
);

// Project Details Component
const ProjectDetails = ({ project, onClose, onPrev, onNext }) => (
    <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-gray-900 bg-opacity-90 p-6 rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <h3 className="text-2xl font-semibold mb-4 text-white">{project.title}</h3>
            <ImageCarousel images={project.images} />
            <p className="text-gray-300 mb-4">{project.description}</p>
            <div className="mb-4">
                <h4 className="text-lg font-semibold text-white">Challenges</h4>
                <ul className="list-disc list-inside text-gray-300">
                    {project.challenges.map((challenge, index) => (
                        <li key={index}>{challenge}</li>
                    ))}
                </ul>
            </div>
            <div className="mb-4">
                <h4 className="text-lg font-semibold text-white">Solutions</h4>
                <ul className="list-disc list-inside text-gray-300">
                    {project.solutions.map((solution, index) => (
                        <li key={index}>{solution}</li>
                    ))}
                </ul>
            </div>
            <div className="mb-4">
                <h4 className="text-lg font-semibold text-white">Outcome</h4>
                <p className="text-gray-300">{project.outcome}</p>
            </div>
            <div className="flex justify-between mt-6">
                <button onClick={onPrev} className="bg-indigo-600 bg-opacity-80 text-white px-4 py-2 rounded hover:bg-opacity-100 transition-all">Previous Project</button>
                <button onClick={onClose} className="bg-gray-600 bg-opacity-80 text-white px-4 py-2 rounded hover:bg-opacity-100 transition-all">Close</button>
                <button onClick={onNext} className="bg-indigo-600 bg-opacity-80 text-white px-4 py-2 rounded hover:bg-opacity-100 transition-all">Next Project</button>
            </div>
        </div>
    </div>
);

// Projects Component
const Projects = () => {
    const [selectedProject, setSelectedProject] = useState(null);

    const projects = [
        {
            title: "Four-Function Calculator",
            description: "A basic scientific calculator built and coded from scratch.",
            technologies: ["C/C++", "Arduino", "Computer-Aided Design"],
            challenges: ["Implementing proper functions and order of operations", "Optimizing data-input and output for a responsive experience"],
            solutions: ["Solutions Coming Soon"],
            outcome: "Learning the basics of embedded system development and design.",
            images: ["/Project-photos/filler.jpg", "/Project-photos/filler.jpg", "/Project-photos/filler.jpg"]
        },
        {
            title: "Replication of Targeted Content Algorithm",
            description: "An open-source research-based project to mimic the results of targeted content algorithms.",
            technologies: ["Python", "NumPy", "MySQL"],
            challenges: ["Implementing calculations for different actions made by the testing process."],
            solutions: ["Solutions Coming Soon"],
            outcome: "Learning the basics of developing scalable python algorithms.",
            images: ["/Project-photos/filler.jpg", "/Project-photos/filler.jpg", "/Project-photos/filler.jpg"]
        },
        {
            title: "Restaurant Floor Management System",
            description: "A responsive executable application for handling restaurant management at all scales.",
            technologies: ["Java", "Raspberry Pi", "MySQL"],
            challenges: ["Implementing and connecting database features to executable application."],
            solutions: ["Solutions Coming Soon"],
            outcome: "Learning the basics of developing scalable full stack java applications.",
            images: ["/Project-photos/filler.jpg", "/Project-photos/filler.jpg", "/Project-photos/filler.jpg"]
        },
    ];

    const handleProjectClick = (index) => {
        setSelectedProject(index);
    };

    const handleCloseProject = () => {
        setSelectedProject(null);
    };

    const handlePrevProject = () => {
        setSelectedProject((prev) => (prev - 1 + projects.length) % projects.length);
    };

    const handleNextProject = () => {
        setSelectedProject((prev) => (prev + 1) % projects.length);
    };

    return (
        <Section id="projects" title="Projects" gradient="bg-gradient-to-b from-gray-700 to-gray-800">
            <div className="grid md:grid-cols-2 gap-6">
                {projects.map((project, index) => (
                    <ProjectCard
                        key={index}
                        {...project}
                        onClick={() => handleProjectClick(index)}
                    />
                ))}
            </div>
            {selectedProject !== null && (
                <ProjectDetails
                    project={projects[selectedProject]}
                    onClose={handleCloseProject}
                    onPrev={handlePrevProject}
                    onNext={handleNextProject}
                />
            )}
        </Section>
    );
};

// Footer Component
const Footer = () => (
    <footer className="bg-black py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <p className="text-center text-gray-300">
                © {new Date().getFullYear()} Jalen Johnson. All rights reserved.
            </p>
        </div>
    </footer>
);

// Main App Component
const App = () => {
    return (
        <div className="bg-black min-h-screen text-white">
            <Navbar />
            <Hero />
            <TrustedBy />
            <About />
            <Education />
            <Experience />
            <Projects />
            <Footer />
        </div>
    );
};

export default App;