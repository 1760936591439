import { motion } from 'framer-motion';
import {useState} from "react";

const TrustedBy = () => {
    const companies = [
        { name: 'M.J.', url: 'Soon To Come' },
        { name: 'MiiMiiPoke', url: 'Soon To Come' },
        { name: 'R.E.M.S.', url: 'Soon To Come' },
        { name: 'C.I.C.', url: 'Soon To Come' },
    ];

    const [isHovered, setIsHovered] = useState(false);

    const containerVariants = {
        animate: {
            x: [0, -100 * companies.length],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: 'loop',
                    duration: isHovered ? 30 : 15,
                    ease: 'linear',
                },
            },
        },
    };

    return (
        <section id="trusted-by" className="bg-gradient-to-b from-gray-900 to-gray-800 py-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-bold mb-8 text-white text-center">Trusted By</h2>
                <div className="relative overflow-hidden">
                    <motion.div
                        className="flex"
                        variants={containerVariants}
                        animate="animate"
                        onHoverStart={() => setIsHovered(true)}
                        onHoverEnd={() => setIsHovered(false)}
                    >
                        {[...companies, ...companies].map((company, index) => (
                            <motion.div
                                key={index}
                                className="bg-white bg-opacity-10 rounded-lg p-4 w-60 h-24 flex items-center justify-center mx-4 flex-shrink-0"
                                whileHover={{ scale: 1.05 }}
                            >
                                <a
                                    href={company.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-3xl font-semibold text-white hover:text-blue-300 transition-colors"
                                >
                                    {company.name}
                                </a>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default TrustedBy;